/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { QueryKey } from '@tanstack/react-query'
import { useApiMutation, useApiQuery } from '@digital-magic/react-common/lib/api/hooks'
import { BlobType } from '@digital-magic/react-common/lib/utils/zod'
import { apiBaseUrl } from '@constants/configuration'
import { ApiMutationOpts, ApiQueryOpts } from '@api/types'
import { callOnly, receiveOnly, sendFileAndReceive } from '@api/utils'
import { FileId, UploadFileResponse } from './types'

const filesUrl = `${apiBaseUrl}/files`
export const fileUrl = (fileId: FileId): string => `${filesUrl}/${fileId}`

const filesDownloadKey = (fileIds: ReadonlyArray<FileId>): QueryKey => ['fileDownload', ...fileIds]

export const useFileUpload = (opts?: ApiMutationOpts<UploadFileResponse, File>) =>
  useApiMutation({
    mutationFn: (data) => {
      const formData = new FormData()
      formData.append('file', data)
      return sendFileAndReceive({
        method: 'post',
        url: filesUrl,
        responseSchema: UploadFileResponse,
        data: formData
      })
    },
    ...opts
  })

export const useFileDownload = (fileId: FileId, opts?: ApiQueryOpts<Blob>) =>
  useApiQuery({
    queryFn: () =>
      receiveOnly({
        method: 'get',
        url: fileUrl(fileId),
        responseSchema: BlobType,
        responseType: 'blob'
      }),
    queryKey: filesDownloadKey([fileId]),
    ...opts
  })

export const useFilesDownload = (fileIds: ReadonlyArray<FileId>, opts?: ApiQueryOpts<ReadonlyArray<Blob>>) =>
  useApiQuery({
    queryFn: () =>
      Promise.all(
        fileIds.map((fileId) =>
          receiveOnly({
            method: 'get',
            url: fileUrl(fileId),
            responseSchema: BlobType,
            responseType: 'blob'
          })
        )
      ),
    queryKey: filesDownloadKey(fileIds),
    ...opts
  })

export const useFileDelete = (opts?: ApiMutationOpts<void, FileId>) =>
  useApiMutation({
    mutationFn: (fileId) =>
      callOnly({
        method: 'delete',
        url: fileUrl(fileId)
      }),
    ...opts
  })
